<template>
  <div class="tags_warp">
    <div class="header">
      <div class="back" @click="$router.go(-1)">
        <svg-icon icon-class="back"></svg-icon>
      </div>
      <div class="share" @click="openShare">
        <svg-icon icon-class="share"></svg-icon>
      </div>
    </div>
    <div class="topic_box">
      <div class="topic_header">
        <div class="topic_pic">
          <ImgDecypt :src="info.coverImg" :key="info.coverImg" />
        </div>
        <div class="topic_info">
          <h2>#{{ info.name || '未知' }}</h2>
          <div class="topic_frequency">
            <div class="play_num">话题总播放次数 {{ info.playCount | watchCount }}</div>
            <div class="collect" @click="collect">
              <svg-icon :icon-class="info.hasCollected ? 'colleted' : 'collet'" />
              <span>收藏</span>
            </div>
          </div>
        </div>
      </div>
      <van-tabs
        v-model="activeName"
        class="my_tab"
        @change="changeTab"
        background="transparent"
        title-active-color="#ff678f"
        title-inactive-color="#333333"
        animated
        :swipeable="true"
      >
        <van-tab :title="item.name" v-for="(item, index) in tabs" :key="index" :name="item.path">
          <keep-alive>
            <router-view v-if="activeName == item.path" class="tab_view" />
          </keep-alive>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import ImgDecypt from '@/components/ImgDecypt';
import { Tab, Tabs, Toast } from 'vant';
import { queryTagInfo } from '@/api/community';
import { careCollectVideo } from '@/api/user';
import { getSessionItem, removeSession } from '@/utils/longStorage';
import { addTopicHistory } from '@/utils/index.js';
import store from '@/store';

export default {
  name: 'tags',
  inject: ['reload'],
  components: {
    ImgDecypt,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      activeName: '',
      info: {},
      tabs: [
        {
          path: '/tags/video',
          name: '抖音',
        },
        {
          path: '/tags/graphics',
          name: '图文',
        },
        {
          path: '/tags/movies',
          name: '影视',
        },
      ],
      include: ['tagsVideo', 'tagsMovies', 'tagsGraphics'],
      oldId: this.$route.query.id,
      tagID: '',
    };
  },
  watch: {
    $route(to, from) {
      let fromPath = this.$route.query.fromPath;
      let id = this.$route.query.id;
      if ((fromPath == '/tags/video' || fromPath == '/tags/graphics') && this.oldId != id) {
        this.oldId = this.$route.query.id;
        this.reload();
      }
    },
  },
  created() {
    this.activeName = this.$route.path;
    this.tagID = this.$route.query.id;
    if (getSessionItem('tagId')) {
      this.tagID = getSessionItem('tagId');
    }
    this.getInfo();
  },
  methods: {
    //获取标签信息
    async getInfo() {
      let req = {
        tagID: this.tagID,
      };
      let res = await this.$Api(queryTagInfo, req);
      if (res.code === 200) {
        this.info = res.data;

        this.$bus.$emit('dataBuriedEvent', {
          dataType: 2,
          tagsName: this.info.name,
        });

        return;
      }
      Toast(res.tip || '获取话题信息失败');
    },
    //收藏
    async collect(tag) {
      let isCollect = this.info.hasCollected;
      let req = {
        isCollect: !isCollect,
        objIds: [this.info.id],
        type: 'tag',
      };
      let res = await this.$Api(careCollectVideo, req);
      if (res.code === 200) {
        this.info.hasCollected = !isCollect;
        if (isCollect) {
          Toast('取消收藏');
        } else {
          Toast('收藏成功');
        }
        return;
      }
      Toast(res.tip || '操作失败');
    },
    changeTab(name) {
      this.$router.replace({
        path: name,
        query: {
          id: this.$route.query.id,
        },
      });
    },
    openShare() {
      this.$store.commit('user/UPDATE_SHARE', {
        show: true,
        info: {
          title: '网黄Up的分享平台，随时随地发现性鲜事',
          publisher: {
            name: this.info.name,
          },
          cover: this.info.coverImg,
          isVerScreen: true,
          isTag: true,
        },
      });
    },
  },
  beforeDestroy() {
    removeSession('tagId');
    addTopicHistory(this.info);
  },
};
</script>
<style scoped lang="scss">
.tags_warp {
  height: 100%;
  .my_tab,
  /deep/ .van-tabs__content,
  /deep/ .van-tab__pane {
    height: 100%;
  }

  .header {
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 20px;
    padding: 0 20px;
    background: #ffffff;
    .back {
      svg {
        font-size: 24px;
      }
    }

    .share {
      font-size: 24px;
    }
  }

  .topic_box {
    height: $tagsDetailsHeight;

    .topic_header {
      display: flex;
      justify-content: space-between;
      margin: 10px 12px;
      padding: 10px;
      background-color: #ffffff;
      border-radius: 4px;

      .topic_pic {
        width: 64px;
        height: 64px;
        border-radius: 11px;
        overflow: hidden;
        margin-right: 7px;
      }

      .topic_info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h2 {
          font-size: 18px;
          color: #333333;
        }

        .topic_frequency {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .play_num {
            color: #999999;
            font-size: 12px;
          }

          .collect {
            font-size: 13px;
            width: 71px;
            height: 30px;
            background: #ebe8f1;
            color: #666666;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            > svg {
              font-size: 16px;
              padding-right: 5px;
              color: red;
            }
          }
        }
      }
    }

    .my_tab {
      .tab_view {
        box-sizing: border-box;
        height: 100%;
        //overflow-y: auto;
      }

      /deep/ .van-tab {
        font-size: 16px;
        display: flex;
        justify-content: space-around;
        flex: unset;
        padding: 0 20px;
      }

      /deep/ .van-tabs__line {
        bottom: 18px;
        width: 20px;
        height: 4px;
        border-radius: 2px;
        background: #ff678f;
      }
      /deep/ .van-tabs__nav--line {
        display: flex;
        justify-content: center;
      }
      /deep/ .van-tab__text {
        font-size: 18px;
      }
    }
  }
}
</style>
